@font-face {
  font-family: 'century-gothic';
  src: url("/public/fonts/CenturyGothic.ttf") format('woff2')
}

@font-face {
  font-family: 'poppins';
  src: url("/public/fonts/Poppins-Regular.ttf") format('woff2')
}

@font-face {
  font-family: 'poppins-extralight';
  src: url("/public/fonts/Poppins-ExtraLight.ttf") format('woff2')
}

@font-face {
  font-family: 'poppins-semibolt';
  src: url("/public/fonts/Poppins-SemiBold.ttf") format('woff2')
}


.App {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  background-color: black;
}

.at {
  position: relative;
  top: -2px;
}

svg {
  display: block;
}

.top {
  position: relative;
  height: 80vh;
  width: 100vw;
  background-image: url('/public/images/prechod_black4k.png'), url('/public/images/background_4k.jpg');
  background-size: 100% 50%, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: bottom, center center;
}

.slogan {
  margin: 10px 0 0 0;
  font-size: 36px;
  color: white;
  font-family: 'poppins-semibolt', sans-serif;
}

.logo_container {
  position: absolute;
  bottom: 100px;
  left: 150px;
}

.socials_div {
  display: flex;
  width: 200px;
  position: absolute;
  bottom: 100px;
  right: 100px;
  justify-content: space-around;
}

.socialLogos {
  width: 70px;
  height: 70px;
}

.greenUnderscore {
  margin-top: 20px;
}

.aboutUs>h1 {
  color: white;
  display: flex;
  justify-content: center;
  font-family: 'poppins-semibolt', sans-serif;

}

.aboutUs>div {
  max-width: 100vw;
  display: flex;
  justify-content: center;
}

.aboutUs>div>p {
  max-width: 30vw;
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
  font-family: 'poppins-extralight', sans-serif;
}

.aboutUs {
  justify-content: center;
  margin-top: 40px;
}

.contactIcon {
  width: 30px;
  height: 30px;
}

.contact_div {
  width: 100vw;
  background-color: #0d0d0d;
  display: flex;
  justify-content: center;
}

.contact {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1000px;
  height: 80px;
}

.contact>div {
  display: flex;
  align-items: center;
}

.contactText {
  color: white;
  margin-left: 10px;
  font-family: 'poppins', sans-serif;
}

.whiteUnderline {
  width: 5000px;
  height: 4px;
}

.footer {
  color: grey;
  background-color: #0d0d0d;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-family: 'poppins', sans-serif;
}

@media screen and (max-width: 2500px) {
  .logo_container {
    bottom: 50px;
    left: 100px;
  }

  .aboutUs>div>p {
    max-width: 50vw;
  }
}

@media screen and (max-width: 1920px) {
  .top {
    background-image: url('/public/images/prechod_black4k.png'), url('/public/images/background_33_fullhd.jpg');
  }
}

@media screen and (max-width: 1750px) {
  .logo_container {
    bottom: 50px;
    left: 100px;
  }

  .aboutUs>div>p {
    max-width: 70vw;
  }
}

@media screen and (max-width: 1300px) {
  .logo_container {
    bottom: 50px;
    left: 70px;
  }

  .aboutUs>div>p {
    max-width: 70vw;
  }

  @media screen and (max-width: 1200px) {
    .bgLogo {
      width: 200px;
    }

    .slogan {
      margin: -10px 0 0 0;
      font-size: 28px;
      color: white;
    }

    .greenUnderscore {
      margin-top: 15px;
    }

    .socials_div {
      display: flex;
      width: 200px;
      position: absolute;
      bottom: 50px;
      right: 100px;
      justify-content: space-around;
    }
  }

  @media screen and (max-width: 750px) {
    .logo_container {
      left: 50px;
    }

    .socials_div {
      display: flex;
      width: 200px;
      position: absolute;
      bottom: 50px;
      right: 50px;
      justify-content: space-around;
    }
  }

  @media screen and (max-width: 650px) {
    .logo_container {
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .logo {
      display: flex;
      justify-content: center;
    }

    .socials_div {
      display: flex;
      justify-content: space-around;
      bottom: -30px;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .socialLogos {
      width: 60px;
    }

    .slogan {
      display: flex;
      justify-content: center;
      font-size: 24px;
    }

    .aboutUs>div>p {
      max-width: 80vw;
    }

    .contact {
      width: 100vw;
      flex-direction: column;
      height: auto;
    }

    .top {
      background-position: bottom, center center;
      background-image: url('/public/images/prechod_black4k.png'), url('/public/images/MobileBackground.png');
    }
  }
}